import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import PageSection from '../../common/section'
import styled from 'styled-components'
import { device } from '../../components/device'
import { PrimaryButton } from '../../components/styled/buttons'
import { navigate } from 'gatsby-link'

const Text = styled.div`
  flex: 1;

  @media ${device.tabletMaxL} {
    margin: -15px;
  }
`

const Veihjelp = () => {
  const data = useStaticQuery(graphql`
    query {
      mg: file(relativePath: { eq: "mobilitetsgaranti.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      viking: file(relativePath: { eq: "falck_redningsbil_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const title = 'Veihjelp'
  const description1 =
    'Hos BilXtra verksted får du 12 måneders Veihjelp med på kjøpet, hvis du bestiller BilXtra – service med Mobilitetsgaranti, eller service ut fra bilens servicehefte.'
  const description2 =
    'Med BilXtra Veihjelp får du hjelp uansett tid og sted i  hele Norden i et helt år. Ved å utføre service hos BilXtra verksted, vil Mobilitetsgarantien fornyes med 12 måneder og gi deg ekstra trygghet.'
  return (
    <SubPageLayout title={title} description1={description1} description2={description2}>
      <SEO title={title} />

      <PageSection
        ///title=""
        image={data.viking.childImageSharp.fluid}
      >
        <Text>
          <h4>BilXtra Veihjelp – hjelp når du trenger det</h4>
          <p>
            Din bil vil ikke starte, eller får driftsstans under kjøring? Du har hatt et uhell –
            utforkjøring eller kollisjon? Du har vært uheldig og låst deg ute av bilen? Bilnøkkelen
            er ødelagt eller mistet? Bilen har gått tom for drivstoff? Det er fylt feil drivstoff på
            tanken? Du har vært uheldig og punktert og trenger hjelp til å bytte dekk?
          </p>
          <h4>Dine fordeler</h4>
          <ul>
            <li>Ingen egenandel.</li>
            <li>Hjelp på stedet eller på din hjemstedsadresse.</li>
            <li>Gratis transport til nærmeste BilXtra verksted.</li>
            <li>Assistanse 24 timer i døgnet, alle dager i året, i hele Norden.</li>
          </ul>
          <PrimaryButton
            type="button"
            style={{ marginLeft: '20%', width: '50%', marginTop: '5rem' }}
            onClick={() => navigate('https://www.vikingredning.no/bestill-veihjelp/bilxtra')}
          >
            Bestill veihjelp her
          </PrimaryButton>
        </Text>
      </PageSection>
    </SubPageLayout>
  )
}

export default Veihjelp
